.animateHighlight {
  position: relative;
  z-index: 1;
}

.animateHighlight::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(54, 100%, 71%, 0.75);
  transform-origin: center right;
  animation: highlight 3s infinite;
}

.animateHighlight:hover::before {
  transform: scaleX(1);
  transform-origin: center left;
}

@keyframes highlight {
  0% {
    left: 0;
    right: 100%;
  }
  25% {
    left: 0;
    right: 0;
  }
  50% {
    left: 100%;
    right: 0;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
