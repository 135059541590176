// PRIVATE LAYOUT

.private-grid-container {
  display: grid;
  grid-template-areas:
    'header header'
    'main main';
  grid-template-columns: 250px 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.private-grid-header {
  grid-area: header;
  width: 100%;
}

.private-grid-sidebar {
  grid-area: sidebar;
  border-right: 1px solid LightGray;
  padding: 1rem;
  display: none;
}

.private-grid-main {
  grid-area: main;
  overflow-y: auto;
  background-color: WhiteSmoke;
  background-blend-mode: overlay, screen, color-burn, screen, normal;
  padding: 1rem;
}

// .private-grid-footer {
//   grid-area: footer;
//   width: 100%;
// }

@media (min-width: 768px) {
  .private-grid-sidebar {
    display: block;
  }
  .private-grid-container {
    grid-template-areas:
      'header header'
      'sidebar main';
  }
}

// PUBLIC LAYOUT
.public-grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.public-grid-header {
  grid-area: header;
  width: 100%;
}

.public-grid-main {
  grid-area: main;
  overflow-y: auto;
  // background-color: WhiteSmoke;
  background-blend-mode: overlay, screen, color-burn, screen, normal;
}
